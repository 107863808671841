import { yupResolver } from '@hookform/resolvers/yup';
import {
  FeatureFlagInternalType,
  PaymentReceiver,
  PlaceFeatureFlag,
  PlateCheckerConnector,
  ReminderType,
} from '@sim-admin-frontends/data-access-admin-be';
import {
  Button,
  FormInput,
  FormSelect,
  FormSwitcherWithLabel,
  FormTextarea,
  FormUpload,
  SUPPORTED_WEB_IMAGE_FORMATS,
  SwitchableFormSection,
  TSelectItem,
  TSelectItems,
} from '@sim-admin-frontends/ui-shared';
import { isEmpty } from '@sim-admin-frontends/utils-shared';
import { BaseSyntheticEvent, FC, useEffect, useMemo, useState } from 'react';
import { get, FormProvider, useForm, useWatch } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';
import { CountryCode } from '@sim-admin-frontends/data-access';

import { STREET_SWEEPING_URL, WASTE_MANAGEMENT_URL } from '../../../constants/Constants';
import {
  PaymentReceiverOption,
  TBuiltInFeatureFlagTypes,
  TDefaultLanguageSettings,
  TPlaceFeatureFlagValues,
} from '../../../types/TFeatureFlags';
import {
  getInitialChatbotSettings,
  getInitialMessagingProblemSettings,
  getInitialPayParkingSettings,
  getInitialPlateCheckerSettings,
  getInitialTUICountryCode,
  getInitialTicketmasterSettings,
  getPlaceFeatureFlagType,
  transformInitialReminderSettings,
} from '../../../utils/placeFeatureFlagsUtils';
import {
  getReminderTypeOptions,
  getReminderVisualizationOptions,
} from '../../../utils/reminderUtils';
import { StyledFormWrapper } from '../../common/featureFlags/FeatureFlagFormStyles';
import { ButtonsWrapper, SwitchableSectionContentWrapper, Wrapper } from '../../common/Formstyles';
import ReminderEdit from '../../reminders/ReminderEdit';
import { reminderSettingsValidation } from './validation';
import { iconValidation } from '../../../utils/formUtils';
import PlaceFeatureChatbotSection from './PlaceFeatureChatbotSection';
import PlaceFeatureEditPayParkingSection from './PlaceFeatureEditPayParkingSection';
import FeatureEditLanguagesSection from '../../common/featureFlags/FeatureEditLanguagesSection';
import { getInitialTitleLocalizations } from '../../../utils/featureFlagsUtils';
import PlaceFeatureEditReportProblemSection from './PlaceFeatureEditReportProblemSection';
import { TMessagingCategory } from '../../../types/TReportProblemCategories';
import PlaceFeatureEditTicketmasterSection from './PlaceFeatureEditTicketmasterSection';

const DISABLE_SIMPLICHAT_URL = 'DisableSimplyChat';

const Divider = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spaces.spacing16};
`;

export const URL_REGEX =
  /^(https?:\/\/(www\.)?|www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

const schema = (t: TFunction) => {
  return Yup.object().shape({
    order: Yup.string().required(t('form.fieldRequired')),
    title: Yup.string().required(t('form.fieldRequired')),
    description: Yup.string().required(t('form.fieldRequired')),
    url: Yup.string().when('type', {
      is: (type?: TSelectItem | null) => type?.value === FeatureFlagInternalType.Iframe,
      then: Yup.string().matches(URL_REGEX, t('form.urlFormat')),
    }),
    type: Yup.mixed().test(
      'isRequired',
      t('common.validation.required'),
      (value: TSelectItem[] | null) => !!value,
    ),
    icon: Yup.mixed().when('type', {
      is: (type?: TSelectItem | null) => type?.value === FeatureFlagInternalType.Iframe,
      then: iconValidation(t),
    }),
    reminderSettings: Yup.object()
      .when('type', {
        is: (type?: TSelectItem | null) => type?.value === FeatureFlagInternalType.Reminder,
        then: reminderSettingsValidation(t),
      })
      .nullable(true),
    delightedSettings: Yup.object().when('type', {
      is: (type?: TSelectItem | null) => type?.value === FeatureFlagInternalType.Delighted,
      then: Yup.object().shape({
        delightedID: Yup.string().required(t('form.fieldRequired')),
      }),
    }),
    plateCheckerSettings: Yup.object().when('type', {
      is: (type?: TSelectItem | null) => type?.value === FeatureFlagInternalType.PlateChecker,
      then: Yup.object().shape({
        connector: Yup.mixed().test(
          'isRequired',
          t('common.validation.required'),
          (value: TSelectItem[] | null) => !!value,
        ),
      }),
    }),
  });
};

const getBuiltInTypeOptions = () =>
  Object.values(TBuiltInFeatureFlagTypes).reduce<TSelectItem[]>(
    (acc, typeOption) =>
      typeOption === TBuiltInFeatureFlagTypes.DELIGHTED_SDK ||
      typeOption === TBuiltInFeatureFlagTypes.ANY_PAYMENTS
        ? acc
        : [
            ...acc,
            {
              value: typeOption,
              label: typeOption,
            },
          ],
    [],
  );

type PlaceFeatureEditProps = {
  featureFlag?: PlaceFeatureFlag;
  onSubmit: (values: TPlaceFeatureFlagValues) => Promise<void>;
  order?: number;
  reminderCategoriesOptions: TSelectItems;
  isFetchingReminderCategories?: boolean;
  onReminderCategoriesScrollToBottom?: (e: Event) => void;
  institutionName?: string;
  institutionOptions: TSelectItems;
  isFetchingInstitutions?: boolean;
  onInstitutionsSearch?: (text: string) => void;
  onInstitutionsScrollToBottom?: (e: Event) => void;
  defaultLanguageSettings: TDefaultLanguageSettings;
  defaultPotholeCategory?: TMessagingCategory;
  reportProblemCategoriesOptions: TSelectItems;
  reportProblemCategoriesIsLoading: boolean;
  onReportProblemCategoriesScrollBottom: () => void;
};

const PlaceFeatureEdit: FC<PlaceFeatureEditProps> = ({
  featureFlag,
  onSubmit,
  order,
  reminderCategoriesOptions,
  isFetchingReminderCategories,
  onReminderCategoriesScrollToBottom,
  institutionName,
  institutionOptions,
  isFetchingInstitutions,
  onInstitutionsSearch,
  onInstitutionsScrollToBottom,
  defaultLanguageSettings,
  defaultPotholeCategory,
  reportProblemCategoriesOptions,
  reportProblemCategoriesIsLoading,
  onReportProblemCategoriesScrollBottom,
}) => {
  const { t } = useTranslation();
  const reminderTypeOptions = getReminderTypeOptions(t);
  const visualizationOptions = getReminderVisualizationOptions(t);

  const defaultReminderSettings = {
    type: reminderTypeOptions[0],
    categories: [],
    parserSettings: {
      parser: '',
      apiUrl: '',
    },
  };

  const translateType = (internalType: FeatureFlagInternalType) => {
    const map = {
      [FeatureFlagInternalType.Iframe]: t('featureFlagType.iframe'),
      [FeatureFlagInternalType.BuiltIn]: t('featureFlagType.builtIn'),
      [FeatureFlagInternalType.Delighted]: t('featureFlagType.delighted'),
      [FeatureFlagInternalType.Reminder]: t('featureFlagType.reminder'),
      [FeatureFlagInternalType.AnyPayments]: t('featureFlagType.anyPayments'),
      [FeatureFlagInternalType.Default]: t('featureFlagType.defaultDeprecated'),
      [FeatureFlagInternalType.Chatbot]: t('featureFlagType.simplichat'),
      [FeatureFlagInternalType.PlateChecker]: t('featureFlagType.licensePlates'),
      [FeatureFlagInternalType.VolunteeringMode]: t('featureFlagType.volunteeringMode'),
      [FeatureFlagInternalType.DisableSimplichat]: t('featureFlagType.disableSimplichat'),
      [FeatureFlagInternalType.PayParking]: t('featureFlagType.payParking'),
      [FeatureFlagInternalType.TuiCityId]: t('featureFlagType.tuiCityId'),
      [FeatureFlagInternalType.TicketmasterEvents]: t('featureFlagType.TicketmasterEvents'),
      [FeatureFlagInternalType.TheInfatuationRestaurants]: t(
        'featureFlagType.TheInfatuationRestaurants',
      ),
      [FeatureFlagInternalType.ChatContextEnabled]: t('featureFlagType.chatContextEnabled'),
      [FeatureFlagInternalType.PayWall]: t('featureFlagType.payWall'),
      [FeatureFlagInternalType.ViatorDestination]: t('featureFlagType.viatorDestination'),
      [FeatureFlagInternalType.Itinerary]: t('featureFlagType.mainScreenItinerary'),
      [FeatureFlagInternalType.PlanMyTrip]: t('featureFlagType.planMyTrip'),
      [FeatureFlagInternalType.CelebrityItineraries]: t('featureFlagType.celebrityItineraries'),
      // [FeatureFlagInternalType.Services]: t('featureFlagType.services'),
    };
    return map[internalType];
  };

  const typeOptions = useMemo(
    () =>
      Object.values(FeatureFlagInternalType).map((typeOption) => ({
        value: typeOption,
        label: translateType(typeOption),
      })),
    [],
  );

  const allPaymentTypeOptions = useMemo(
    () =>
      Object.values(PaymentReceiver).map((typeOption) => ({
        value: typeOption,
        label: typeOption,
      })),
    [],
  );

  const plateCheckerConnectorOptions = useMemo(
    () =>
      Object.values(PlateCheckerConnector).map((connectorOption) => ({
        value: connectorOption,
        label: connectorOption,
      })),
    [],
  );

  const tuiCountryCodeOptions = useMemo(
    () =>
      Object.values(CountryCode).map((connectorOption) => ({
        value: connectorOption,
        label: connectorOption,
      })),
    [],
  );

  const builtInTypeOptions = getBuiltInTypeOptions();

  const initialBuiltInTypeOption = useMemo(
    () => builtInTypeOptions.find((option) => option.value === featureFlag?.url),
    [],
  );

  const initialValues: TPlaceFeatureFlagValues = {
    // only FE field
    disableProblemsOnMap: featureFlag?.disableProblemsOnMap ?? false,
    isFullnameRequired: featureFlag?.isFullnameRequired ?? false,
    builtInType: initialBuiltInTypeOption || undefined,
    order: order,
    icon: featureFlag?.icon ? [featureFlag?.icon] : null,
    title: featureFlag?.title,
    description: featureFlag?.description,
    url: featureFlag?.url,
    enabled: !!featureFlag?.enabled,
    reminderSettings: transformInitialReminderSettings({
      reminderSettings: featureFlag?.reminderSettings,
      reminderCategoriesOptions,
      reminderTypeOptions,
      visualizationOptions,
      institutionName,
    }),
    allowedPaymentReceivers: featureFlag?.allowedPaymentReceivers
      ? featureFlag?.allowedPaymentReceivers.map((item) => ({ label: item, value: item }))
      : [],
    type: getPlaceFeatureFlagType(featureFlag),
    delightedSettings: {
      delightedID: featureFlag?.delightedSettings?.delightedID || '',
    },
    messagingProblemSettings: getInitialMessagingProblemSettings(
      featureFlag,
      defaultPotholeCategory,
    ),
    chatBotSettings: getInitialChatbotSettings(featureFlag),
    plateCheckerSettings: getInitialPlateCheckerSettings(featureFlag),
    payParkingSettings: getInitialPayParkingSettings(featureFlag),
    titleLocalizations: getInitialTitleLocalizations(featureFlag?.titleLocalizations || undefined),
    visitorMode: featureFlag?.visitorMode ?? false,
    isSystem: featureFlag?.isSystem ?? false,
    tuiCityId: featureFlag?.tuiCityId,
    tuiCountryCode: getInitialTUICountryCode(featureFlag),
    ticketmasterSettings: getInitialTicketmasterSettings(featureFlag, institutionName),
    theInfatuationPlaceId: featureFlag?.theInfatuationPlaceId,
    chatContextEnabled: featureFlag?.chatContextEnabled,
    viatorDestination: featureFlag?.viatorDestination,
  };
  const [areLanguages, setAreLanguages] = useState(!!initialValues.titleLocalizations);

  const submit = async (values: TPlaceFeatureFlagValues) => {
    if (onSubmit) {
      return await onSubmit(values);
    }
    return null;
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema(t)),
    mode: 'all',
  });

  const { handleSubmit, formState, setValue, control, trigger, register, getValues } = methods;
  const { errors, isSubmitting } = formState;

  const [previewReminderType, featureFlagType, builtInType] = useWatch({
    name: ['reminderSettings.type', 'type', 'builtInType'],
    control,
  });

  const onDescriptionChange = (event: BaseSyntheticEvent) => {
    const description = event.target.value;
    setValue('description', description);
    trigger('description');
  };

  const defaultPaymentReceivers = useMemo(() => {
    const allowedReceivers = initialValues.allowedPaymentReceivers ?? [];
    const reducedReceivers = allowedReceivers.reduce<PaymentReceiverOption[]>((acc, receiver) => {
      const allowedReceiver = allPaymentTypeOptions.find((opt) => opt.value === receiver.value);
      if (allowedReceiver) {
        return [...acc, allowedReceiver];
      }
      return acc;
    }, []);
    return reducedReceivers;
  }, []);

  const getUrlFromReminderType = (type: ReminderType) => {
    const map = {
      [ReminderType.WasteManagement]: WASTE_MANAGEMENT_URL,
      [ReminderType.StreetSweeping]: STREET_SWEEPING_URL,
    };
    return map[type];
  };

  useEffect(() => {
    if (previewReminderType?.value) {
      setValue('url', getUrlFromReminderType(previewReminderType?.value));
    } else {
      setValue('url', initialValues.url);
    }
  }, [previewReminderType]);

  const resetBuiltInValues = () => {
    setValue('reminderSettings', undefined);
    setValue('delightedSettings', undefined);
    setValue('chatBotSettings', undefined);
    setValue('builtInType', undefined);
    setValue('allowedPaymentReceivers', undefined);
    setValue('plateCheckerSettings', undefined);
    setValue('payParkingSettings', undefined);
    setValue('url', undefined);
  };

  const onTypeChange = (typeOption: readonly TSelectItem[] | null) => {
    const typeValue = typeOption?.[0]?.value;
    if (typeValue === featureFlagType?.value) {
      return;
    }
    resetBuiltInValues();

    if (typeValue === FeatureFlagInternalType.Reminder) {
      setValue(
        'reminderSettings',
        initialValues.reminderSettings || {
          ...defaultReminderSettings,
        },
      );
    }
    if (typeValue === FeatureFlagInternalType.Delighted) {
      setValue(
        'delightedSettings',
        initialValues.delightedSettings || {
          delightedID: '',
        },
      );
      setValue('url', TBuiltInFeatureFlagTypes.DELIGHTED_SDK);
    }
    if (typeValue === FeatureFlagInternalType.AnyPayments) {
      setValue('allowedPaymentReceivers', defaultPaymentReceivers);
      setValue('url', TBuiltInFeatureFlagTypes.ANY_PAYMENTS);
    }
    if (typeValue === FeatureFlagInternalType.DisableSimplichat) {
      setValue('url', DISABLE_SIMPLICHAT_URL);
    }
    if (typeValue === FeatureFlagInternalType.BuiltIn) {
      setValue('url', builtInTypeOptions[0].value);
    }
    if (
      typeValue === FeatureFlagInternalType.Chatbot ||
      FeatureFlagInternalType.PlateChecker ||
      FeatureFlagInternalType.PayParking ||
      FeatureFlagInternalType.TuiCityId ||
      FeatureFlagInternalType.TicketmasterEvents ||
      FeatureFlagInternalType.TheInfatuationRestaurants ||
      FeatureFlagInternalType.ChatContextEnabled ||
      FeatureFlagInternalType.ViatorDestination
    ) {
      setValue('url', typeValue);
    }
    if (typeValue === initialValues.type?.value) {
      setValue('url', initialValues.url);
    }
    trigger('url');
  };

  useEffect(() => {
    if (!builtInType) {
      return;
    }
    if (builtInType?.value !== TBuiltInFeatureFlagTypes.REPORT_PROBLEM) {
      setValue('messagingProblemSettings', undefined);
    }
    setValue('url', (builtInType as TSelectItem)?.value);
    trigger('url');
  }, [builtInType]);

  const onLanguagesSectionVisibilityChanged = (open: boolean) => {
    setAreLanguages(open);
    if (!open) {
      setValue('titleLocalizations', undefined);
    }
  };

  const onVisitorModeChange = (value: boolean) => {
    setValue('visitorMode', value);
  };

  const onIsSystemChange = (value: boolean) => {
    setValue('isSystem', value);
  };

  const onChatContextEnabledChangeMap = (value: boolean) => {
    setValue('chatContextEnabled', {
      landmarks: { ...getValues('chatContextEnabled')?.landmarks, map: value },
    });
  };

  const onChatContextEnabledChangeMain = (value: boolean) => {
    setValue('chatContextEnabled', {
      landmarks: { ...getValues('chatContextEnabled')?.landmarks, main: value },
    });
  };

  return (
    <Wrapper>
      <StyledFormWrapper>
        <FormProvider {...methods}>
          <FormInput
            testId="FeatureFlagEdit#Order"
            label={t('places.features.form.order')}
            {...register('order')}
            defaultValue={initialValues.order ?? ''}
            error={errors.order}
          />
          <FormUpload
            dropzoneLabel={t('places.features.form.icon')}
            testId="FeatureFlagEdit#Icon"
            name="icon"
            t={t}
            control={control}
            accept={SUPPORTED_WEB_IMAGE_FORMATS}
            fileTypeErrorLabel={t('common.validation.png')}
          />
          <FormInput
            testId="FeatureFlagEdit#Title"
            label={t('places.features.form.title')}
            {...register('title')}
            defaultValue={initialValues.title ?? ''}
            error={errors.title}
          />
          <FormSelect
            control={control}
            name="type"
            label={t('institutions.features.form.type')}
            options={typeOptions}
            defaultValue={initialValues.type}
            onChange={onTypeChange}
            error={get(errors, 'type')}
            testId="FeatureFlagEdit#Type"
          />
          <FormTextarea
            testId="FeatureFlagEdit#Description"
            label={t('places.features.form.description')}
            name={'description'}
            onChange={onDescriptionChange}
            defaultValue={initialValues.description ?? ''}
            error={errors.description}
          />
          <Divider />
          {featureFlagType?.value === FeatureFlagInternalType.Reminder && (
            <ReminderEdit
              methods={methods}
              initialValues={initialValues.reminderSettings}
              reminderCategoriesOptions={reminderCategoriesOptions}
              isFetchingReminderCategories={isFetchingReminderCategories}
              onReminderCategoriesScrollToBottom={onReminderCategoriesScrollToBottom}
              institutionOptions={institutionOptions}
              isFetchingInstitutions={isFetchingInstitutions}
              onInstitutionsSearch={onInstitutionsSearch}
              onInstitutionsScrollToBottom={onInstitutionsScrollToBottom}
            />
          )}
          {featureFlagType?.value === FeatureFlagInternalType.Delighted && (
            <FormInput
              testId="FeatureFlagEdit#DelightedId"
              label={t('places.features.form.delightedID')}
              {...register('delightedSettings.delightedID')}
              defaultValue={initialValues.delightedSettings?.delightedID ?? ''}
              error={get(errors, 'delightedSettings.delightedID')}
            />
          )}
          {featureFlagType?.value === FeatureFlagInternalType.AnyPayments && (
            <FormSelect
              isMulti
              control={control}
              name="allowedPaymentReceivers"
              label={t('places.features.form.allowedPaymentReceivers')}
              options={allPaymentTypeOptions}
              defaultValue={defaultPaymentReceivers}
              testId="FeatureFlagEdit#PaymentReceivers"
            />
          )}
          {featureFlagType?.value === FeatureFlagInternalType.Iframe && (
            <FormInput
              testId="FeatureFlagEdit#URL"
              label={t('places.features.form.url')}
              {...register('url')}
              error={errors.url}
            />
          )}
          {featureFlagType?.value === FeatureFlagInternalType.BuiltIn && (
            <FormSelect
              control={control}
              name="builtInType"
              label={t('institutions.features.form.builtInType')}
              options={builtInTypeOptions}
              defaultValue={initialValues.builtInType}
              testId="FeatureFlagEdit#BultInType"
            />
          )}
          {builtInType?.value === TBuiltInFeatureFlagTypes.REPORT_PROBLEM && (
            <PlaceFeatureEditReportProblemSection
              initialValues={initialValues}
              reportProblemCategoriesOptions={reportProblemCategoriesOptions}
              reportProblemCategoriesIsLoading={reportProblemCategoriesIsLoading}
              onReportProblemCategoriesScrollBottom={onReportProblemCategoriesScrollBottom}
            />
          )}
          {featureFlagType?.value === FeatureFlagInternalType.Chatbot && (
            <PlaceFeatureChatbotSection
              control={control}
              initialChatbotSettings={initialValues?.chatBotSettings}
            />
          )}
          {featureFlagType?.value === FeatureFlagInternalType.PlateChecker && (
            <FormSelect
              control={control}
              name="plateCheckerSettings.connector"
              label={t('places.features.form.connector')}
              options={plateCheckerConnectorOptions}
              defaultValue={initialValues.plateCheckerSettings?.connector}
              error={get(errors, 'plateCheckerSettings.connector')}
              testId="FeatureFlagEdit#plateConnector"
            />
          )}
          {featureFlagType?.value === FeatureFlagInternalType.PayParking && (
            <PlaceFeatureEditPayParkingSection initialValues={initialValues.payParkingSettings} />
          )}
          {featureFlagType?.value === FeatureFlagInternalType.TuiCityId && (
            <FormInput
              testId="FeatureFlagEdit#TuiCityId"
              label={t('places.features.form.tuiCityId')}
              {...register('tuiCityId')}
              error={errors.tuiCityId}
              type={'number'}
            />
          )}

          {featureFlagType?.value === FeatureFlagInternalType.TuiCityId && (
            <FormSelect
              control={control}
              name="tuiCountryCode"
              label={t('places.features.form.tuiCountryCode')}
              options={tuiCountryCodeOptions}
              defaultValue={initialValues.tuiCountryCode}
              error={get(errors, 'tuiCountryCode')}
              testId="FeatureFlagEdit#tuiCountryCode"
              searchable
            />
          )}

          {featureFlagType?.value === FeatureFlagInternalType.TicketmasterEvents && (
            <PlaceFeatureEditTicketmasterSection
              initialValues={initialValues}
              institutionOptions={institutionOptions}
              isFetchingInstitutions={isFetchingInstitutions}
              onInstitutionsSearch={onInstitutionsSearch}
              onInstitutionsScrollToBottom={onInstitutionsScrollToBottom}
            />
          )}

          {featureFlagType?.value === FeatureFlagInternalType.TheInfatuationRestaurants && (
            <FormInput
              testId="FeatureFlagEdit#InfatuationPlaceId"
              label={t('places.features.form.infatuationPlaceId')}
              {...register('theInfatuationPlaceId')}
              type={'string'}
            />
          )}

          {featureFlagType?.value === FeatureFlagInternalType.ChatContextEnabled && (
            <>
              <Divider />
              <FormSwitcherWithLabel
                label={t('places.edit.chatContextEnabledMap')}
                initialValue={initialValues.chatContextEnabled?.landmarks?.map ?? false}
                onChange={onChatContextEnabledChangeMap}
              />
              <Divider />
              <Divider />
              <FormSwitcherWithLabel
                label={t('places.edit.chatContextEnabledMain')}
                initialValue={initialValues.chatContextEnabled?.landmarks?.main ?? false}
                onChange={onChatContextEnabledChangeMain}
              />
              <Divider />
            </>
          )}

          {featureFlagType?.value === FeatureFlagInternalType.ViatorDestination && (
            <FormInput
              testId="FeatureFlagEdit#ViatorDestination"
              label={t('places.features.form.viatorDestination')}
              {...register('viatorDestination')}
              error={errors.viatorDestination}
              type={'number'}
            />
          )}

          <SwitchableFormSection
            title={t('places.features.form.titleLanguages')}
            description={t('places.features.form.titleLanguagesDescription')}
            onVisibilityChanged={onLanguagesSectionVisibilityChanged}
            initiallyOpened={areLanguages}
            testId={'FeatureFlagEdit#languagesSwitch'}
          >
            <SwitchableSectionContentWrapper>
              <FeatureEditLanguagesSection
                defaultLanguageSettings={defaultLanguageSettings}
                initialValues={initialValues.titleLocalizations}
              />
            </SwitchableSectionContentWrapper>
          </SwitchableFormSection>
          <Divider />
          <FormSwitcherWithLabel
            label={t('places.edit.visitorModeSection')}
            initialValue={initialValues.visitorMode ?? false}
            onChange={onVisitorModeChange}
          />
          <Divider />
          <FormSwitcherWithLabel
            label={t('places.edit.isSystemSection')}
            initialValue={initialValues.isSystem ?? false}
            onChange={onIsSystemChange}
          />
          <Divider />
          <ButtonsWrapper>
            <Button
              size="smaller"
              type="submit"
              onClick={handleSubmit(submit)}
              isLoading={isSubmitting}
              disabled={isSubmitting || !isEmpty(errors)}
              testId="FeatureFlagEdit#CreateFlag"
            >
              {featureFlag
                ? t('places.features.form.updateFlag')
                : t('places.features.form.createFlag')}
            </Button>
          </ButtonsWrapper>
        </FormProvider>
      </StyledFormWrapper>
    </Wrapper>
  );
};

export default PlaceFeatureEdit;
